import React, {useCallback, useEffect, useRef, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from "./Logo"
import styles from './index.module.scss'
import classNames from 'classnames'

const cn = classNames.bind(styles)

const Header = () => {
  const location = useLocation()
  const ref = useRef(null)
  const [menuIsOpen, setIsOpenMenu] = useState(false)
  const isBookingPage = location.pathname.includes('/booking')
    && !location.pathname.includes('/success')
    && !location.pathname.includes('/rejected')
  const isReservationPage = location?.pathname?.includes('reservation')

  useEffect(() => {
    // Function to handle window resize event
    const handleResize = () => {
      if (menuIsOpen && window.innerWidth > 701) {
        document.body.classList.remove('menu-open')
        document.getElementsByTagName('main')?.[0].classList.remove('menu-open')
        setIsOpenMenu(false)
      }
    }
    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [menuIsOpen, setIsOpenMenu])

  const handleMenuIconClick = useCallback(() => {
    if (!menuIsOpen) {
      document.body.classList.add('menu-open')
      document.getElementsByTagName('main')?.[0].classList.add('menu-open')
    } else {
      document.body.classList.remove('menu-open')
      document.getElementsByTagName('main')?.[0].classList.remove('menu-open')
    }
    setIsOpenMenu(!menuIsOpen)
  }, [menuIsOpen, setIsOpenMenu])

  return (
    <header
      className={cn(
        'container',
        menuIsOpen ? styles.HeaderContainerOpen: '',
        isReservationPage ? styles.HeaderReservationPageContainer : '',
      )}
    >
      <div className={cn(
        styles.HeaderContent,
        styles.HeaderContent,
        isBookingPage ? styles.HeaderBookingContent : '',
        isReservationPage ? styles.HeaderReservationPage : '',
      )}>
        <Logo menuIsOpen={menuIsOpen || isReservationPage} />

        {
          !isBookingPage && !isReservationPage && (
            <>
              <nav
                className={cn(styles.Nav)}
              >
                <Link to="/">Главная</Link>
                <Link to="/team">Наша команда</Link>
                <Link to="/faq">FAQ</Link>
                <Link to="/rental">Разместить свой объект</Link>
              </nav>

              <div className={styles.MobileMenu}>
                <div
                  ref={ref}
                  className={cn(
                    styles.MenuIcon,
                    menuIsOpen && styles.MenuIconOpen,
                  )}
                  onClick={handleMenuIconClick}
                />

                {menuIsOpen && (
                  <ul
                    className={cn(styles.MenuList)}
                  >
                    <li onClick={() => handleMenuIconClick()}>
                      <Link to="/">Главная</Link>
                    </li>
                    <li onClick={() => handleMenuIconClick()}>
                      <Link to="/team">Наша команда</Link>
                    </li>
                    <li onClick={() => handleMenuIconClick()}>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li onClick={() => handleMenuIconClick()}>
                      <Link to="/rental">Разместить свой объект</Link>
                    </li>
                  </ul>
                )}
              </div>
            </>
          )
        }
      </div>
    </header>
  )
}

export default Header
