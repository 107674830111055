import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import classNames from 'classnames'

import styles from './index.module.scss'
import { useScrollToTop } from '../../components/ScrollToTop'
import { fetchReservation, ObjectReservationProps } from '../../api'
import { formatNumberWithThousandsSeparator } from "../../helpers"

const cn = classNames.bind(styles)
const ReservationPage = () => {
  const { id } = useParams()
  const [object, setObject] = useState<ObjectReservationProps | null>(null)

  useEffect(() => {
    id && fetchReservation(id, setObject)
  }, [])

  useScrollToTop()

  const booking = object?.object
  const isHelicopter = booking?.type === 'helicopter'
  const price = object?.price ? formatNumberWithThousandsSeparator((+object.price).toFixed(0)) : 0

  let forOneNight: string | undefined | number = 0
  if (isHelicopter) {
    const startTime = dayjs(object?.start_at)
    const endTime = dayjs(object?.end_at)
    const diffHours = endTime?.diff(startTime, 'hour')

    forOneNight = object?.price && diffHours > 0 ? formatNumberWithThousandsSeparator((+object.price / diffHours).toFixed(0)) : price
  } else {
    const firstDate = dayjs(object?.end_at).hour(12).minute(0).second(0)
    const secondDate = dayjs(object?.start_at).hour(12).minute(0).second(0)
    const dayDiff = firstDate.diff(secondDate, "day")

    forOneNight = object?.price && dayDiff > 0 ? formatNumberWithThousandsSeparator((+object?.price / dayDiff).toFixed(0)) : price
  }

  return (
    <main>
      <section className={styles.MainSection}>
        <div className={styles.Container}>
          <span className={styles.MainSectionSubTitle}>
            Информация о бронировании
          </span>
          <h1>
            {object?.object?.name}
          </h1>
          <span className={cn(styles.MainSectionSubTitle, styles.MainSectionCountry)}>
            {object?.object?.country?.name}
          </span>
        </div>
      </section>

      <section className={cn(styles.Container, styles.Content)}>
        <div className={styles.ContentImgContainer}>
          <img src={object?.object?.images?.[0]?.image} alt={object?.object?.images?.[0]?.name} />
        </div>

        <div className={styles.ContentInfo}>
          <span>Код бронирования</span>
          <span>{object?.id}</span>
        </div>
        <h2>
          Ваши данные
        </h2>
        <div className={styles.ContentInfo}>
          <span>ФИО плательщика</span>
          <span>{object?.customer_name_and_surname}</span>
        </div>
        <div className={styles.ContentInfo}>
          <span>Даты аренды</span>
          <span>
            {
              isHelicopter
                ? dayjs(object?.start_at).format('DD.MM.YYYY HH:mm')
                : dayjs(object?.start_at).format('DD.MM.YYYY')
            }
            {` - `}
            {
              isHelicopter
                ? dayjs(object?.end_at).format('DD.MM.YYYY HH:mm')
                : dayjs(object?.end_at).format('DD.MM.YYYY')
            }
          </span>
        </div>
        <div className={styles.ContentInfo}>
          <span>Стоимость аренды в сутки</span>
          <span>{forOneNight} {object?.price_currency}</span>
        </div>
        <div className={styles.ContentInfo}>
          <span>Итоговая стоимость аренды</span>
          <span>{price} {object?.price_currency}</span>
        </div>
        <h2>
          Описание
        </h2>
        <p>
          {+(object?.price || 0) <= 5000 ? 'Оплата за услуги сопутствующие аренде недвижимости' : object?.object?.description}
        </p>
      </section>
    </main>
  )
}

export default ReservationPage
