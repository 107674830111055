import React, {useEffect, useState} from 'react'
import QuestionItem from '../../FAQ/components/QuestionItem'
import { QuestionItemProps } from '../../FAQ/components/QuestionItem.d'
import { fetchQuestions } from '../../../api'

const Questions = ({
  className,
}: { className?: string }) => {
  const [questions, setQuestions] = useState<QuestionItemProps[]>([])

  useEffect(() => {
    fetchQuestions(setQuestions)
  }, [])

  return (
    <div className={className ?? ''}>
      {
        questions.map((item) => {
          return <QuestionItem key={item.id} {...item} />
        })
      }
    </div>
  )
}

export default Questions
