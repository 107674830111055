import React from 'react'
import styles from './GreyImageShape.module.scss'
import classNames from 'classnames'

const cn = classNames.bind(styles)

const GreyImageShape = ({
  className,
}: { className?: string }) => {
  return (
    <div className={cn(styles.Shape, className ?? '')} />
  )
}

export default GreyImageShape
