export const validateRussianPhoneNumber = (phoneNumber: string) => {
  const regex = /^(\+7|8)[0-9]{10}$/
  return regex.test(phoneNumber)
}

export function formatNumberWithThousandsSeparator(number: string) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export function isValidEmail(email: string) {
  // Regular expression pattern for a valid email address
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  // Test the email against the pattern
  return pattern.test(email)
}

export const getPrice = ({
  price, type,
}: { price?: string, type?: string}) => {
  if (!price) {
    return undefined
  }

  return type === 'helicopter' ? (+price / 24).toString() : price
}
