import React, { useState } from 'react'
import styles from './index.module.scss'
import { Field, Formik } from 'formik'
import attachIcon from '../../assets/attach.svg'
import trashIcon from '../../assets/trash.svg'
import Button from '../Button'
import classNames from 'classnames'
import success from '../../assets/success.svg'
import { postBecameAnAgent } from '../../api'
import { validateRussianPhoneNumber } from '../../helpers'

const cn = classNames.bind(styles)

// const allowedFileTypes = ['application/pdf', 'image/jpeg', 'image/png', 'application/msword']

const SectionBecameAnAgent = () => {
  const [step, setStep] = useState<'form' | 'success'>('form')
  const isFormStep = step === 'form'
  const handleFileClick = () => {
    document.getElementById('file-input')?.click()
  }

  return (
    <section
      className={cn('container', styles.AgentFormSection )}
    >
      <div
        className={cn(styles.DescriptionSection, styles.AgentForm)}
      >
        <div>
          <h3 className={styles.Title}>
            Основные требования <br />к агенту
          </h3>

          <ul className={styles.List}>
            <li>
              Агентом может стать любое лицо, зарегистрированное как
              Индивидуальный Предприниматель согласно действующего
              законодательства РФ
            </li>
            <li>
              Иметее опыт бронирования туристических услуг и успешной
              коммуникации с клиентами.
            </li>
            <li>
              Каждый агент проходит обязательное собеседование и
              аттестацию.
            </li>
          </ul>
        </div>

        {isFormStep ? (
          <div className={styles.FormWrapper}>
            <Formik
              initialValues={{
                email: '',
                fullName: '',
                phone: '',
                description: '',
                file: '',
              }}
              validate={(values) => {
                const errors = {}
                if (!values.email) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  errors.email = 'Обязательное поле'
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.email,
                  )
                ) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  errors.email = 'Неверный формат'
                }
                if (!validateRussianPhoneNumber(values.phone)) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  errors.phone = 'Неверный формат'
                }

                if (!values.fullName) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  errors.fullName = 'Обязательное поле'
                }
                return errors
              }}
              onSubmit={async (
                values,
                { setSubmitting, resetForm },
              ) => {
                setSubmitting(false)
                const fileInput =
                  document.getElementById('file-input')
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const file = fileInput?.files?.[0]
                const blob = new Blob([file], { type: file.type })

                await postBecameAnAgent({
                  name: values.fullName,
                  phone: values.phone,
                  message: values.description,
                  email: values.email,
                  attachment: blob,
                  fileName: file.name,
                })
                setStep('success')
                resetForm()
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue,
                handleSubmit,
                touched,
                /* and other goodies */
              }) => (
                <form
                  onSubmit={(e) => {
                    e?.preventDefault()
                    handleSubmit()
                  }}
                  className={styles.Form}
                >
                  <input
                    required={true}
                    type="text"
                    name="fullName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fullName}
                    placeholder="Имя и фамилия"
                    className={
                      touched.fullName && errors.fullName
                        ? styles.FormErrorInput
                        : ''
                    }
                  />
                  {touched.fullName && errors.fullName && (
                    <span className={styles.FormError}>
                      {errors.fullName}
                    </span>
                  )}

                  <input
                    required={true}
                    type="tel"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    placeholder="Номер телефона"
                    className={
                      touched.phone && errors.phone
                        ? styles.FormErrorInput
                        : ''
                    }
                  />
                  {touched.phone && errors.phone && (
                    <span className={styles.FormError}>
                      {errors.phone}
                    </span>
                  )}

                  <input
                    required={true}
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Почта"
                    className={
                      touched.email && errors.email
                        ? styles.FormErrorInput
                        : ''
                    }
                  />
                  {touched.email && errors.email && (
                    <span className={styles.FormError}>
                      {errors.email}
                    </span>
                  )}

                  <input
                    required={true}
                    type="text"
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    placeholder="Краткое описание опыта"
                  />

                  <div className={styles.AttachContainer}>
                    <div
                      className={styles.AttachIcon}
                      onClick={handleFileClick}
                    >
                      <img src={attachIcon} alt="Attach Icon" />
                    </div>
                    <div
                      className={styles.AttachDescription}
                      onClick={handleFileClick}
                    >
                      <span>Прикрепить файл</span>
                      <span className={styles.AttachTypes}>PDF, JPG, DOC, PNG</span>
                    </div>
                    {values.file && (
                      <div
                        className={styles.AttachTrash}
                        onClick={() => setFieldValue('file', '')}
                      >
                        <img src={trashIcon} alt="Trash icon" />
                      </div>
                    )}

                    <Field
                      id="file-input"
                      type="file"
                      name="file"
                      accept=".pdf,.jpg,.jpeg,.doc,.docx,.png"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.file}
                    />
                  </div>

                  <Button
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !values.phone ||
                      !values.email ||
                      !values.fullName ||
                      !values.description
                    }
                  >
                    Стать агентом
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <div className={styles.FormSuccess}>
            <div>
              <img src={success} alt="Success" />
            </div>
            <h2>Готово</h2>

            <p>
              Мы получили вашу заявку. После рассмотрения вашей
              анкеты, менеджер свяжется с вами
            </p>
          </div>
        )}
      </div>
    </section>
  )
}

export default SectionBecameAnAgent
