import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'
import applicationImg from '../../assets/application.png'
import pointsImg from '../../assets/points-img.svg'
import cardImg from '../../assets/card.png'

const cn = classNames.bind(styles)
const MainSection = () => {
  return (
    <section className={cn('container')}>
      <h2 className={styles.RentalItemTitle}>Как мы работаем</h2>

      <div className={styles.RentalItem}>
        <div className={styles.RentalItemContentWrapper}>
          <span className={styles.RentalItemCircle}>1</span>
          <h3>Оставьте заявку</h3>
          <p>
            Вы оставляете заявку на сайте. Наши представители в
            течении 1 часа свяжутся с Вами.
          </p>
        </div>
        <div className={styles.RentalItemImgWrapper}>
          <img src={applicationImg} alt="Form Aplication" />
        </div>
      </div>

      <div className={cn(styles.RentalItem, styles.RentalItemGap)}>
        <div
          className={cn(
            styles.RentalItemImgWrapper,
            styles.RentalItemImgPoints,
          )}
        >
          <img
            src={pointsImg}
            alt="Image with passport, suitcases and road sign"
          />
        </div>
        <div className={styles.RentalItemContentWrapper}>
          <span className={styles.RentalItemCircle}>2</span>
          <h3>Дополнительные сервисы</h3>
          <p>
            Предложим за Вас дополнительные сервисы (трансфер,
            экскурсии, консьерж-сервис) потенциальному жильцу, чтобы
            сделать ваш объект недвижимости доступнее и
            привлекательнее!
          </p>
        </div>
      </div>

      <div className={styles.RentalItem}>
        <div className={styles.RentalItemContentWrapper}>
          <span className={styles.RentalItemCircle}>3</span>
          <h3>Оплата</h3>
          <p>
            Мы отправим деньги на вашу карту сразу как жилец произведет оплату
            через наш сервис. Все оплаты происходят официально от лица
            компании Booking Port и соответствуют всем требованиям цифровой безопасности НСПК «МИР».
          </p>
          <Link to="/offer-rental" className={styles.RentalItemLink}>
            Публичная оферта
          </Link>
        </div>
        <div className={styles.RentalItemImgWrapper}>
          <img src={cardImg} alt="Silver card" />
        </div>
      </div>
    </section>
  )
}

export default MainSection
