import React from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import styles from './index.module.scss'
import Button from '../../components/Button'
import SectionBecameAnAgent from '../../components/SectionBecameAnAgent'
import { useScrollToTop } from '../../components/ScrollToTop'

const cn = classNames.bind(styles)
const TeamPage = () => {
  const navigate = useNavigate()
  useScrollToTop()

  return (
    <main className={cn('container', styles.TeamPageContainer)}>
      <section>
        <div className={cn(styles.SectionBanner)}>
          <div className={styles.BannerTop}>
            <h1 className={styles.BannerTitle}>
              Хотите стать <br/> членом команды?
            </h1>

            <p className={styles.BannerDescription}>
              Стань частью успешной команды агентов <br />
              Booking Port по бронированию туристической <br />
              недвижимости за рубежом!
            </p>
          </div>

          <div className={styles.BannerBottom}>
            <Button onClick={() => navigate('/agent-form')}>
              Подать заявку
            </Button>

            <p className={styles.BannerSubscription}>
              Гарантированный бонус за каждую <br />
              оплаченную заявку по бронированию!
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className={styles.DescriptionSection}>
          <h3 className={styles.Title}>
            Схема работы с агентом
          </h3>

          <ul className={styles.AgentList}>
            <li>
              Агент по поручению Booking Port  осуществляет поиск,
              коммуникацию с клиентами: подбор вилл, яхт, вертолетов
              согласно пожеланий и бюджета клиента. Booking Port берет
              на себя все расчёты с зарубежными контрагентами,
              предоставляет базу туристической недвижимости.
            </li>
            <li>
              Клиент, для которого агент забронировал недвижимость,
              должен будет заключить договор бронирования с агентом и
              произвести оплату.
            </li>
            <li>
              Агент перечисляет компании Booking Port средства
              согласно агентского договора по каждой заявке
              (поручению) бронирования. Далее Booking Port согласно
              отчета агента выплачивает на расчётный счет агента
              вознаграждение по каждой заявке (поручению)
              бронирования.
            </li>
            <li>
              Агент информирует клиентов о любых изменениях по заявке,
              осуществляет все постпродажные процессы и обслуживание в
              коммуникации с Booking Port
            </li>
          </ul>
        </div>
      </section>

      <SectionBecameAnAgent />
    </main>
  )
}

export default TeamPage
