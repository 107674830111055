import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import logoSmall from '../../assets/small-logo.svg'
import logoBig from '../../assets/logo-green-big.svg'
import useResize from "../../helpers/useResize"

import styles from './index.module.scss'

const Logo = ({
  menuIsOpen,
}: {
  menuIsOpen: boolean
}) => {
  const { width } = useResize()

  return (
    menuIsOpen ? (
      <Link to="/" className={styles.Logo}>
        <img src={logoBig} alt="Logo" />
      </Link>
    ) : (
      <Link to="/" className={styles.Logo}>
        { width >= 935 || width <= 700 || menuIsOpen ? <img src={logo} alt="Logo" /> : <img src={logoSmall} alt="Logo" /> }
      </Link>
    )
  )
}

export default Logo
