import React from 'react'
import styles from './ImgItem.module.scss'
import { ImgItemProps } from './ImgItem.d'
import classNames from 'classnames'
import { formatNumberWithThousandsSeparator } from '../../helpers'

const cn = classNames.bind(styles)

const ImgItem = ({
  withImgCaption,
  classNameWrapper,
  classNameImage,
  classNameCaption,

  imgUrl,
  name,
  description,
  price,
  type,
}: ImgItemProps) => {
  return (
    <div className={cn(styles.ImgWrapper, classNameWrapper || '')}>
      <div className={cn(withImgCaption ? styles.ImgContainerWithCaption : styles.ImgContainer, price ? styles.ImgWithPrice : '')}>
        <img src={imgUrl} alt={name} className={classNameImage || ''}/>
        {withImgCaption && <span className={classNameCaption || ''}>{name}</span>}
        {price && (
          <div className={styles.ImgPrice}>
            {price ? formatNumberWithThousandsSeparator((+price).toFixed(0)) : 0} ₽
            <span>/ {type === 'helicopter' ? 'час' : 'сутки' }</span>
          </div>
        )}
      </div>
      {
        !withImgCaption && (
          <div className={styles.ImgDescription}>
            {name && <span className={styles.ImgCountry}>{name}</span>}
            <span className={styles.ImgProduct}>{description}</span>
          </div>
        )
      }
    </div>
  )
}

export default ImgItem
