import React from 'react'
import Questions from './components/Questions'
import styles from './index.module.scss'
import classNames from 'classnames'
import { useScrollToTop } from '../../components/ScrollToTop'

const cn = classNames.bind(styles)

const FAQPage = () => {
  useScrollToTop()
  return (
    <main>
      <section className={cn('container')}>
        <div className={styles.PageHeader}>
          <h1 className={styles.Title}>Как мы работаем</h1>

          <p className={styles.Description}>
            Дорогие клиенты! <br/>
            Куда бы Вы ни направлялись, мы сделаем <br className={styles.DescriptionBr}/>
            ваше путешествие  максимально комфортным <br className={styles.DescriptionBr}/>
            и запоминающимся!
          </p>
        </div>

        <div className={styles.StepContainer}>
          <div className={styles.Step}>
            <span className={styles.StepNum}>
              1
            </span>
            <span className={styles.StepTitle}>
              Оставьте заявку
            </span>
            <div className={styles.StepDescription}>
              Вы оставляете заявку
              на сайте. Наши представители <br/>
              в течении 1 часа свяжутся
              с Вами.
            </div>
          </div>

          <div className={styles.Step}>
            <span className={styles.StepNum}>
              2
            </span>
            <span className={styles.StepTitle}>
              Подбор
            </span>
            <div className={styles.StepDescription}>
              Мы подбираем для Вас самую лучшую
              туристическую недвижимость и программу,
              с учетом всех пожеланий
              и бюджета.
            </div>
          </div>

          <div className={styles.Step}>
            <span className={styles.StepNum}>
              3
            </span>
            <span className={styles.StepTitle}>
              Оформление
            </span>
            <div className={styles.StepDescription}>
              Оформление всех необходимых документов.
              Наличный и безналичный расчёт.
            </div>
          </div>

          <div className={styles.Step}>
            <span className={styles.StepNum}>
              4
            </span>
            <span className={styles.StepTitle}>
              Вы наслаждаетесь <br/>
              отдыхом!
            </span>
          </div>
        </div>

      </section>

      <Questions />
    </main>
  )
}

export default FAQPage
