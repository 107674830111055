import React from 'react'
import classNames from 'classnames'
import { useScrollToTop } from '../../components/ScrollToTop'
import MainSection from './MainSection'
import SectionRentalItem from './SectionRentalItem'
import Button from "../../components/Button"
import GreyImageShape from "../../components/GreyImageShape/GreyImageShape"

import styles from './index.module.scss'

const cn = classNames.bind(styles)
const RentalPage = () => {
  useScrollToTop()

  const scrollDown = () => {
    window.scrollTo({
      top: 2500,
      behavior: "smooth",
    })
  }

  return (
    <main className={styles.MainContainer}>
      <section className={cn('container')}>
        <div className={styles.MainSection}>
          <div
            className={cn(
              styles.MainSectionContainer,
            )}
          >
            <h1>
              Сдавайте вашу <br />
              недвижимость <br />
              за рубежом
              <span>быстро и <br/> безопасно!</span>
            </h1>

            <Button onClick={scrollDown}> Оставить заявку</Button>
          </div>

          <div className={styles.ImagesContainer}>
            <GreyImageShape className={styles.GreyImageShape1}/>
            <img
              src={require('assets/sunset.png')}
              alt="Villa"
              className={styles.VillaImg}
            />
            <GreyImageShape className={styles.GreyImageShape2}/>
            <GreyImageShape className={styles.GreyImageShape3}/>
            <GreyImageShape className={styles.GreyImageShape4}/>
          </div>
        </div>
      </section>
      {/*<section className={styles.MainSection}>*/}
      {/*  <div className={cn('container', styles.MainSectionContainer)}>*/}
      {/*    <div>*/}
      {/*      <h1>*/}
      {/*        Сдавайте вашу <br />*/}
      {/*        недвижимость <br />*/}
      {/*        за рубежом*/}
      {/*        <span>быстро и <br/> безопасно!</span>*/}
      {/*      </h1>*/}

      {/*      <Button onClick={scrollDown}> Оставить заявку</Button>*/}
      {/*    </div>*/}
      {/*    <img*/}
      {/*      className={styles.MainSectionImg}*/}
      {/*      src={require('assets/villas.png')}*/}
      {/*      alt="Villa in the grass, Villa with pool and Villa with palm trees"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</section>*/}

      <MainSection />
      <SectionRentalItem />
    </main>
  )
}

export default RentalPage
