import React, { useState } from 'react'
import styles from './index.module.scss'
import { Field, Formik } from 'formik'
import classNames from 'classnames'

import attachIcon from '../../../assets/attach.svg'
import trashIcon from '../../../assets/trash.svg'
import Button from './../../../components/Button'
import success from '../../../assets/success.svg'
import { postBecameAnAgent } from '../../../api'
import { validateRussianPhoneNumber } from '../../../helpers'

const cn = classNames.bind(styles)

const SectionRentalItem = () => {
  const [step, setStep] = useState<'form' | 'success'>('form')
  const isFormStep = step === 'form'
  const handleFileClick = () => {
    document.getElementById('file-input')?.click()
  }

  return (
    <section className={'container'} id='get-in-touch'>
      <div className={cn(styles.AgentFormSection)}>
        <div
          className={cn(
            styles.DescriptionSection,
            styles.AgentForm,
            styles.Container,
            'container',
          )}
        >
          <div className={styles.DescriptionWrapper}>
            <h3 className={styles.Title}>
              Заявка на размещение объекта
            </h3>

            <p>
              Заполните анкету максимально подробно и загрузите как
              можно больше фотографий. После того как вы отправите
              заявку, в ближайшее время наши менеджеры свяжутся с Вами
            </p>
          </div>

          {isFormStep ? (
            <div className={styles.FormWrapper}>
              <Formik
                initialValues={{
                  email: '',
                  fullName: '',
                  phone: '',
                  address: '',
                  description: '',
                  file: '',
                  price: '',
                }}
                validate={(values) => {
                  const errors = {}
                  if (!values.email) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    errors.email = 'Обязательное поле'
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email,
                    )
                  ) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    errors.email = 'Неверный формат'
                  }
                  if (!validateRussianPhoneNumber(values.phone)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    errors.phone = 'Неверный формат'
                  }

                  if (!values.fullName) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    errors.fullName = 'Обязательное поле'
                  }
                  return errors
                }}
                onSubmit={async (
                  values,
                  { setSubmitting, resetForm },
                ) => {
                  setSubmitting(false)
                  const fileInput =
                    document.getElementById('file-input')
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const file = fileInput?.files?.[0]
                  const blob = file?.type ? new Blob([file], { type: file?.type }) : undefined

                  await postBecameAnAgent({
                    name: values.fullName,
                    phone: values.phone,
                    message: `Цена: ${values.price}, Адресс: ${values.address}, Описание: ${values.description}`,
                    email: values.email,
                    attachment: blob,
                    fileName: file?.name,
                  })
                  setStep('success')
                  resetForm()
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  setFieldValue,
                  handleSubmit,
                  touched,
                  /* and other goodies */
                }) => (
                  <form
                    onSubmit={(e) => {
                      e?.preventDefault()
                      handleSubmit()
                    }}
                    className={styles.Form}
                  >
                    <input
                      required={true}
                      type="text"
                      name="fullName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullName}
                      placeholder="Имя и фамилия"
                      className={
                        touched.fullName && errors.fullName
                          ? styles.FormErrorInput
                          : ''
                      }
                    />
                    {touched.fullName && errors.fullName && (
                      <span className={styles.FormError}>
                        {errors.fullName}
                      </span>
                    )}

                    <input
                      required={true}
                      type="tel"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      placeholder="Номер телефона"
                      className={
                        touched.phone && errors.phone
                          ? styles.FormErrorInput
                          : ''
                      }
                    />
                    {touched.phone && errors.phone && (
                      <span className={styles.FormError}>
                        {errors.phone}
                      </span>
                    )}

                    <input
                      required={true}
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Почта"
                      className={
                        touched.email && errors.email
                          ? styles.FormErrorInput
                          : ''
                      }
                    />
                    {touched.email && errors.email && (
                      <span className={styles.FormError}>
                        {errors.email}
                      </span>
                    )}

                    <input
                      required={true}
                      type="text"
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      placeholder="Адрес объекта"
                    />

                    <input
                      required={true}
                      type="text"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      placeholder="Описание объекта"
                    />

                    <input
                      required={true}
                      type="text"
                      name="price"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.price}
                      placeholder="Ориентировочная стоимость в сутки "
                    />

                    <div className={styles.AttachContainer}>
                      <div
                        className={styles.AttachIcon}
                        onClick={handleFileClick}
                      >
                        <img src={attachIcon} alt="Attach Icon" />
                      </div>
                      <div
                        className={styles.AttachDescription}
                        onClick={handleFileClick}
                      >
                        <span>Прикрепить файл</span>
                        <span>PDF, JPG, DOC, PNG</span>
                      </div>
                      {values.file && (
                        <div
                          className={styles.AttachTrash}
                          onClick={() => setFieldValue('file', '')}
                        >
                          <img src={trashIcon} alt="Trash icon" />
                        </div>
                      )}

                      <Field
                        id="file-input"
                        type="file"
                        name="file"
                        accept=".pdf,.jpg,.jpeg,.doc,.docx,.png"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.file}
                      />
                    </div>

                    <Button
                      type="submit"
                      disabled={
                        isSubmitting ||
                        !values.phone ||
                        !values.email ||
                        !values.fullName ||
                        !values.description
                      }
                    >
                      Подать заявку
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          ) : (
            <div className={styles.FormSuccess}>
              <div>
                <img src={success} alt="Success" />
              </div>
              <h2>Готово</h2>

              <p>
                Мы получили вашу заявку. После рассмотрения вашей
                анкеты, менеджер свяжется с вами
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default SectionRentalItem
