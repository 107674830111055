import React, { useEffect, useState } from 'react'
import InnerPageContainer from '../../components/InnerPageContainer'
import ImgItem from '../../components/ImgItem/ImgItem'
import styles from './index.module.scss'
import { ObjectProps, fetchObjectList } from '../../api'
import { useScrollToTop } from '../../components/ScrollToTop'
import { Link } from 'react-router-dom'

const RealEstatePage = () => {
  const [realEstateList, setRealEstateList] = useState<ObjectProps[]>(
    [],
  )

  useEffect(() => {
    fetchObjectList({ setObjectList: setRealEstateList, type: 'real_estate' })
  }, [])

  useScrollToTop()

  return (
    <main className={'container'}>
      <InnerPageContainer title="Рекомендуем к посещению">
        {realEstateList?.map((item) => {
          return (
            <Link key={item.id} to={`/offers/${item.id}`}>
              <ImgItem
                withImgCaption={false}
                key={item.id}
                imgUrl={item.images?.[0]?.image}
                name={item.country.name}
                description={item.name}
                classNameImage={styles.Image}
                classNameCaption={styles.Caption}
                classNameWrapper={styles.Container}
              />
            </Link>
          )
        })}
      </InnerPageContainer>
    </main>
  )
}

export default RealEstatePage
