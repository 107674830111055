import React, { useEffect, useState } from 'react'
import styles from '../index.module.scss'
import classNames from 'classnames'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import { ReactComponent as HelicopterIcon } from '../../../assets/helicopter.svg'
import { ReactComponent as VillaIcon } from '../../../assets/villa.svg'
import { ReactComponent as BoatIcon } from '../../../assets/boat.svg'
import Button from '../../../components/Button'
import Select, { Option } from '../../../components/Select'
import ModalOffer from '../../../components/ModalOffer'
import { fetchCountries, ObjectProps } from '../../../api'
import dayjs from "dayjs"
import GreyImageShape from "../../../components/GreyImageShape/GreyImageShape"

const cn = classNames.bind(styles)
const MainSection = () => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [value, onChange] = useState()
  const [offerType, onChangeOfferType] = useState<
    'real_estate' | 'helicopter' | 'yacht'
  >('real_estate')
  const [countryList, setCountryList] = useState<ObjectProps[]>([])
  const [country, setCountry] = useState('')

  useEffect(() => {
    fetchCountries(setCountryList)
  }, [])

  const options = countryList?.map((item) => {
    return { label: item.name, value: item.id }
  }) as Option[]

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <section className={cn('container', styles.MainSectionWrapper)}>
        <div className={styles.MainSection}>
          <div
            className={cn(
              styles.MainSectionContainer,
            )}
          >
            <h1 className={styles.Title}>
              Сделай <br />
              свой отдых <br />
              <span>незабываемым</span>
            </h1>

            <div>
              <div className={styles.ProductTypeContainer}>
                <button
                  className={cn(
                    styles.ProductTypeBtn,
                    offerType === 'real_estate'
                      ? styles.ProductTypeBtnActive
                      : '',
                  )}
                  onClick={() => onChangeOfferType('real_estate')}
                >
                  <VillaIcon />
                  Недвижимость
                </button>
                <button
                  className={cn(
                    styles.ProductTypeBtn,
                    offerType === 'yacht'
                      ? styles.ProductTypeBtnActive
                      : '',
                  )}
                  onClick={() => onChangeOfferType('yacht')}
                >
                  <BoatIcon />
                  Яхта
                </button>
                <button
                  className={cn(
                    styles.ProductTypeBtn,
                    offerType === 'helicopter'
                      ? styles.ProductTypeBtnActive
                      : '',
                  )}
                  onClick={() => onChangeOfferType('helicopter')}
                >
                  <HelicopterIcon />
                  Вертолет
                </button>
              </div>

              <div className={styles.InputContainer}>
                <Select optionsList={options} onChange={setCountry}/>
                <div className={cn(styles.DateRangePickerContainer)}>
                  <span
                    className={cn(
                      styles.DateRangePickerLabel,
                      !value ? styles.DateRangePickerLabelEmpty : '',
                    )}
                  >
                    Даты
                  </span>
                  {
                    <DateRangePicker
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onChange={onChange}
                      value={value}
                      className={cn(
                        styles.DateRangePicker,
                        isCalendarOpen
                          ? styles.DateRangePickerActive
                          : '',
                        !value ? styles.DateRangePickerEmpty : '',
                      )}
                      calendarIcon={null}
                      clearIcon={null}
                      minDate={new Date()}
                      onCalendarOpen={() => setIsCalendarOpen(true)}
                      onCalendarClose={() => setIsCalendarOpen(false)}
                      locale="ru"
                    />
                  }
                </div>
              </div>

              <Button
                className={styles.OfferBtn}
                onClick={() => openModal()}
                disabled={!country}
              >
                Получить предложение
              </Button>
            </div>
          </div>

          <div className={styles.ImagesContainer}>
            <GreyImageShape className={styles.GreyImageShape1}/>
            <img
              src={require('assets/villa-new.png')}
              alt="Villa"
              className={styles.VillaImg}
            />
            <GreyImageShape className={styles.GreyImageShape2}/>
            <GreyImageShape className={styles.GreyImageShape3}/>
            <GreyImageShape className={styles.GreyImageShape4}/>
          </div>
        </div>
      </section>
      <ModalOffer
        isOpen={isModalOpen}
        onClose={closeModal}
        applicationData={{
          countryId: country,
          objectType: offerType,
          start_at: dayjs(value?.[0] || new Date()).hour(12).minute(0).second(0).format('YYYY-MM-DDTHH:mm'),
          end_at:  dayjs(value?.[1]  || new Date()).hour(12).minute(0).second(0).format('YYYY-MM-DDTHH:mm'),
        }}
      />
    </>
  )
}

export default MainSection
