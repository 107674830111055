import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'

const cn = classNames.bind(styles)
const InnerPageContainer = ({
  children,
  title,
  className,
  mainTitle = true,
  bigLayout = false,
}: { children: React.ReactNode, title: string, className?: string, mainTitle?: boolean, bigLayout?: boolean }) => {

  return (
    <section className={cn(styles.Section, className ? className : '')}>
      {
        mainTitle ? (
          <h1 className={styles.SectionTitle}>{title}</h1>
        ) : (
          <h2 className={styles.SectionTitle}>{title}</h2>
        )
      }

      <div>
        <div className={ bigLayout ? styles.SectionContentBig : styles.SectionContent}>
          {children}
        </div>
      </div>
    </section>
  )
}

export default InnerPageContainer
