import React, {useEffect, useState} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import Button from '../../../components/Button'
import QuestionItem from './QuestionItem'
import { QuestionItemProps } from './QuestionItem.d'
import { fetchQuestions } from '../../../api'
import ModalOffer from '../../../components/ModalOffer'

const cn = classNames.bind(styles)
const Questions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [questions, setQuestions] = useState<QuestionItemProps[]>([])

  useEffect(() => {
    fetchQuestions(setQuestions)
  }, [])

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <section className={cn('container', styles.QuestionsSectionContainer)}>
      <div className={styles.QuestionsSection}>
        <div>
          <h3 className={styles.Title}>
            Часто <br/>
            задаваемые <br/>
            вопросы
          </h3>
          <p className={cn(styles.Description, styles.DescriptionDesktop)}>
            Если у вас остались вопросы, <br/>
            оставьте свои контакты и <br/>
            наш менеджер свяжется с вами для <br/>
            подробной информации
          </p>

          <Button className={styles.ButtonDesktop} onClick={openModal}>
            Оставить заявку
          </Button>
        </div>

        <div>
          {
            questions.map((item) => {
              return <QuestionItem key={item.id} {...item} />
            })
          }
        </div>

        <div className={styles.QuestionsSectionMobile}>
          <p className={styles.Description}>
            Если у вас остались вопросы, <br/>
            оставьте свои контакты и <br/>
            наш менеджер свяжется с вами для <br/>
            подробной информации
          </p>

          <Button onClick={openModal}>
            Оставить заявку
          </Button>
        </div>

        <ModalOffer isOpen={isModalOpen} onClose={closeModal}/>
      </div>
    </section>
  )
}

export default Questions
